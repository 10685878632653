import React, { useState, useEffect } from 'react';
import { MessageSquare } from 'lucide-react';

const testimonials = [
  {
    text: "Servizio eccellente e personale altamente qualificato. La mia moto non è mai stata in condizioni migliori!",
    author: "Marco R."
  },
  {
    text: "Officina di alto livello. Hanno risolto un problema complesso con grande professionalità.",
    author: "Laura M."
  },
  {
    text: "Professionalità e attenzione ai dettagli. Un punto di riferimento per gli appassionati Honda.",
    author: "Alessandro F."
  }
];

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % testimonials.length);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  return (
    <section id="testimonials" className="py-24 bg-gradient-to-b from-black to-gray-900">
      <div className="container mx-auto px-4">
        <h2 className="section-title pb-4 mb-16">
          <MessageSquare className="inline-block w-8 h-8 text-red-600 mr-2" />
          Testimonianze
        </h2>

        <div className="max-w-3xl mx-auto relative h-48">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className={`absolute w-full transition-all duration-500 ${
                index === currentIndex
                  ? 'opacity-100 translate-y-0'
                  : 'opacity-0 translate-y-8'
              }`}
            >
              <blockquote className="text-center">
                <p className="text-xl text-gray-300 italic mb-6">"{testimonial.text}"</p>
                <footer className="text-red-600 font-semibold">- {testimonial.author}</footer>
              </blockquote>
            </div>
          ))}
        </div>

        <div className="flex justify-center mt-8 space-x-2">
          {testimonials.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`w-3 h-3 rounded-full transition-all ${
                index === currentIndex ? 'bg-red-600 w-6' : 'bg-gray-600'
              }`}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
