import React from 'react';
import { Wrench, Settings, Paintbrush, Laptop } from 'lucide-react';

const services = [
  {
    icon: Wrench,
    title: 'Manutenzione',
    description: 'Servizi di manutenzione completi per mantenere la tua moto sempre al massimo delle prestazioni.'
  },
  {
    icon: Settings,
    title: 'Riparazioni',
    description: 'Riparazioni professionali effettuate da tecnici specializzati Honda.'
  },
  {
    icon: Paintbrush,
    title: 'Personalizzazione',
    description: 'Trasforma la tua moto con accessori personalizzati e modifiche su misura.'
  },
  {
    icon: Laptop,
    title: 'Diagnostica',
    description: 'Diagnostica avanzata per identificare e risolvere qualsiasi problema. Nella nostra officina utilizziamo il sistema ufficiale HONDA MCS (Motorcycle Communication System) e TEXA DIAGNOSTIC SYSTEM per l’identificazione dei guasti.'
  }
];

const Services = () => {
  return (
    <section id="services" className="py-24 bg-gradient-to-b from-black to-gray-900">
      <div className="container mx-auto px-4">
        <h2 className="section-title pb-4 mb-16">
          <Wrench className="inline-block w-8 h-8 text-red-600 mr-2" />
          I Nostri Servizi
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {services.map((service, index) => (
            <div 
              key={index}
              className="bg-gray-900/50 backdrop-blur-sm p-6 rounded-xl border border-gray-800
                         transform transition-all hover:scale-105 hover:shadow-xl hover:shadow-red-600/20"
            >
              <service.icon className="w-12 h-12 text-red-600 mb-4" />
              <h3 className="text-xl font-bold mb-3">{service.title}</h3>
              <p className="text-gray-400">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
