import React from 'react';
import { Users } from 'lucide-react';

const About = () => {
  return (
    <section id="about" className="py-24 bg-gradient-to-b from-black to-gray-900">
      <div className="container mx-auto px-4">
        <h2 className="section-title pb-4 mb-16">
          <Users className="inline-block w-8 h-8 text-red-600 mr-2" />
          Chi Siamo
        </h2>
        
        <div className="flex flex-col md:flex-row items-center gap-12">
          <div className="flex-1 space-y-6">
            <p className="text-lg text-gray-300 leading-relaxed">
              Da oltre 20 anni, Bost Moto rappresenta l'eccellenza nel mondo delle motociclette. Il nostro centro assistenza autorizzato Honda offre servizi di altissimo livello, garantendo professionalità e passione in ogni dettaglio.
            </p>
            <a href="#services" className="inline-block bg-transparent border-2 border-red-600 text-red-600 px-6 py-3 rounded-lg transition hover:bg-red-600 hover:text-white">
              I nostri servizi
            </a>
          </div>
          
          <div className="flex-1">
            <div
              style={{
                fontFamily: "'Georgia', 'Times New Roman', serif",
                color: "#fffff", // Colore marrone vintage
                textShadow: "1px 1px 2px rgba(0, 0, 0, 0.3)",
                textAlign: "center",
                fontStyle: "italic",
                padding: "20px",
                transition: "transform 0.2s",
                cursor: "pointer",
              }}
              onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
              onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
            >
              <h1 style={{ fontSize: "2.5rem", fontWeight: "bold", margin: "0" }}>
                &ldquo;1 Febbraio 2002&rdquo;
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
