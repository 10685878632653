import React from 'react';
import { Target } from 'lucide-react';

const Mission = () => {
  return (
    <section className="py-24 bg-gradient-to-b from-gray-900 to-black relative overflow-hidden">
      <div className="container mx-auto px-4">
        <h2 className="section-title pb-4 mb-16">
          <Target className="inline-block w-8 h-8 text-red-600 mr-2" />
          La Nostra Missione
        </h2>

        <div className="max-w-3xl mx-auto bg-gray-900/50 backdrop-blur-sm p-8 rounded-2xl shadow-xl border border-gray-800">
          <p className="text-xl text-gray-300 leading-relaxed text-center">
            La nostra missione è offrire eccellenza e sicurezza ai motociclisti, garantendo qualità, innovazione e attenzione ai dettagli in ogni servizio. Puntiamo a mantenere la fiducia dei nostri clienti costruendo relazioni durature basate su trasparenza e professionalità.
          </p>
        </div>

        {/* Elementi decorativi */}
        <div className="absolute -top-40 -left-40 w-80 h-80 bg-red-600/20 rounded-full filter blur-[100px]" />
        <div className="absolute -bottom-40 -right-40 w-80 h-80 bg-red-600/10 rounded-full filter blur-[100px]" />
      </div>
    </section>
  );
};

export default Mission;
