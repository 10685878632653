import React from 'react';
import { MapPin, Clock, Phone } from 'lucide-react';

const WhereWeAre = () => {
  return (
    <section id="location" className="py-24 bg-gradient-to-b from-black to-gray-900">
      <div className="container mx-auto px-4">
        <h2 className="section-title pb-4 mb-16">
          <MapPin className="inline-block w-8 h-8 text-honda-red mr-2" />
          Dove Siamo
        </h2>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <div className="space-y-8">
            <div className="premium-card hover-effect">
              <h3 className="text-2xl font-light mb-6">Visita la Nostra Officina</h3>
              <div className="space-y-4">
                <div className="flex items-start space-x-4">
                  <MapPin className="w-6 h-6 text-honda-red shrink-0 mt-1" />
                  <div>
                    <p className="text-white">Indirizzo</p>
                    <p className="text-neutral-400">Via Renato Caccioppoli, 25, 80125 Napoli NA</p>
                  </div>
                </div>
                
                <div className="flex items-start space-x-4">
                  <Clock className="w-6 h-6 text-honda-red shrink-0 mt-1" />
                  <div>
                    <p className="text-white">Lunedì - Venerdì</p>
                    <p className="text-neutral-400">8:00 - 19:00</p>
                    <p className="text-white mt-2">Sabato</p>
                    <p className="text-neutral-400">8:00 - 14:00</p>
                  </div>
                </div>

                <div className="flex items-start space-x-4">
                  <Phone className="w-6 h-6 text-honda-red shrink-0 mt-1" />
                  <div>
                    <p className="text-white">081 1856 7249</p>
                    <p className="text-neutral-400">Chiamaci per appuntamenti</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="premium-card hover-effect">
              <h3 className="text-2xl font-light mb-4">Come Raggiungerci</h3>
              <div className="space-y-3">
                <p className="text-neutral-400">
                  <span className="text-white">In Metro:</span> -
                </p>
                <p className="text-neutral-400">
                  <span className="text-white">In Autobus:</span> -
                </p>
              </div>
            </div>
          </div>

          <div className="h-[600px] rounded-lg overflow-hidden premium-card hover-effect">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3019.034499538061!2d14.201136315397259!3d40.82720587931953!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x133b0930680e3d49%3A0xf78276ec67125e6!2sBostMoto!5e0!3m2!1sit!2sit!4v1447018212356"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="grayscale contrast-125 opacity-90"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhereWeAre;
