import React from 'react';
import { Handshake } from 'lucide-react';

const partners = [

];

const Partners = () => {
  return (
    <section id="partners" className="py-24 bg-gradient-to-b from-gray-900 to-black">
      <div className="container mx-auto px-4">
        <h2 className="section-title pb-4 mb-16">
          <Handshake className="inline-block w-8 h-8 text-honda-red mr-2" />
          I nostri partner
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {partners.map((partner, index) => (
            <div 
              key={index}
              className="premium-card hover-effect group"
            >
              <div className="aspect-video mb-6 overflow-hidden rounded-lg">
                <img 
                  src={partner.logo}
                  alt={partner.name}
                  className="w-full h-full object-cover transform transition-transform group-hover:scale-110"
                />
              </div>
              <h3 className="text-xl font-semibold mb-2">{partner.name}</h3>
              <p className="text-neutral-400">{partner.description}</p>
            </div>
          ))}
        </div>

        <div className="mt-16 text-center">
          <p className="text-lg text-neutral-400 max-w-2xl mx-auto">
            We collaborate with industry-leading brands to ensure the highest quality service and parts for your motorcycle.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Partners;