import React from 'react';
import { Facebook, Instagram, Youtube } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-black py-12 border-t border-gray-900">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
          <div className="text-center md:text-left">
            <img src="https://cdn.discordapp.com/attachments/1307661004225773650/1307728038024445983/bostmoto.png?ex=673b5c24&is=673a0aa4&hm=f6c68c02ef53ef1dd443b12aa3bdb440770f943147888f8f5af7f4e03564b26f&" alt="Bost Moto" className="h-12 mx-auto md:mx-0 mb-4" />
            <p className="text-gray-400">
              Il tuo partner di fiducia per l'assistenza Honda da oltre 20 anni.
            </p>
          </div>

          <div className="text-center">
            <h3 className="text-xl font-bold mb-4">Link Utili</h3>
            <ul className="space-y-2">
              <li><a href="#home" className="text-gray-400 hover:text-white transition">Home</a></li>
              <li><a href="#about" className="text-gray-400 hover:text-white transition">Chi siamo</a></li>
              <li><a href="#services" className="text-gray-400 hover:text-white transition">Servizi</a></li>
              <li><a href="#contact" className="text-gray-400 hover:text-white transition">Contatti</a></li>
            </ul>
          </div>

          <div className="text-center md:text-right">
            <h3 className="text-xl font-bold mb-4">Seguici</h3>
            <div className="flex justify-center md:justify-end space-x-4">
              <a href="https://www.facebook.com/bostmoto" className="text-gray-400 hover:text-red-600 transition">
                <Facebook className="w-6 h-6" />
              </a>
              <a href="#" className="text-gray-400 hover:text-red-600 transition">
                <Instagram className="w-6 h-6" />
              </a>
            </div>
          </div>
        </div>

        <div className="text-center text-gray-500 text-sm border-t border-gray-900 pt-8">
          © {new Date().getFullYear()} Bost Moto. Tutti i diritti riservati.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
