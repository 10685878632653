import React from 'react';
import { ChevronRight } from 'lucide-react';

const Hero = () => {
  return (
    <section className="relative min-h-screen flex flex-col justify-between overflow-hidden">
      {/* Sfondo */}
      <div className="absolute inset-0">
        <div
          className="absolute inset-0 bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: 'url("https://images.unsplash.com/photo-1635073943212-f4b02a187e4e?auto=format&fit=crop&q=80")',
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-r from-neutral-950 via-neutral-950/85 to-neutral-950/70" />
        </div>
      </div>

      {/* Contenuto */}
      <div className="relative z-10 container mx-auto px-6 sm:px-10 md:px-16 pt-24 sm:pt-32 lg:pt-40">
        <div className="grid grid-cols-1 lg:grid-cols-2 items-center gap-12">
          {/* Contenuto testuale */}
          <div className="text-left">
            <h1 className="text-5xl sm:text-6xl md:text-7xl font-light tracking-tight mb-6 sm:mb-8">
              <span className="block text-neutral-500">| OFFICINA</span>
              | AUTORIZZATA
              <br />
              <span className="text-honda-red">| HONDA SERVICE</span>
            </h1>
            <p className="text-neutral-400 text-lg sm:text-xl md:text-2xl tracking-wide mb-8 sm:mb-10">
              "Preserviamo i tuoi sogni"
            </p>

            {/* Pulsanti */}
            <div className="flex flex-col sm:flex-row gap-4 sm:gap-6">
              <a
                href="#services"
                className="group inline-flex items-center gap-3 px-8 py-4 bg-honda-red text-white
                         hover:bg-red-700 transition-colors tracking-wide text-base uppercase rounded-lg w-fit"
              >
                Esplora i servizi
                <ChevronRight className="w-6 h-6 transition-transform group-hover:translate-x-2" />
              </a>
              <a
                href="#contact"
                className="group inline-flex items-center gap-3 px-8 py-4 border border-neutral-700
                         text-neutral-300 hover:border-neutral-500 hover:text-white
                         transition-colors tracking-wide text-base uppercase rounded-lg w-fit"
              >
                Prenota un appuntamento
                <ChevronRight className="w-6 h-6 transition-transform group-hover:translate-x-2" />
              </a>
            </div>
          </div>

          {/* Spazio vuoto per desktop */}
          <div className="hidden lg:block"></div>
        </div>
      </div>
      {/* Carte */}
      <div className="relative z-10 w-full pb-8 sm:pb-12">
        <div className="container mx-auto px-6 sm:px-10 md:px-16">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 text-center sm:text-left text-sm sm:text-base tracking-wide uppercase">
            <div className="premium-card">
              <span className="text-neutral-500 block">Posizione</span>
              <p className="mt-2 text-lg">Napoli, Italia</p>
            </div>
            <div className="premium-card">
              <span className="text-neutral-500 block">Esperienza</span>
              <p className="mt-2 text-lg">Oltre 20 anni</p>
            </div>
            <div className="premium-card">
              <span className="text-neutral-500 block">Certificazione</span>
              <p className="mt-2 text-lg">Honda Ufficiale</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
