import React from 'react';
import { Cpu } from 'lucide-react';

const systems = [
  {
    name: 'MCS (Motorcycle Comunication System)',
    description: 'Official Honda\'s Diagnostic System for find and solve issue with an advanced software',
    features: ['Real-time diagnostics', 'Software updates', 'Performance analysis']
  },
  {
    name: 'TEXA',
    description: 'An advanced diagnostic system',
    features: ['Real-time diagnostics', 'Software updates', 'Performance analysis', 'Greater compatibility']
  },
];

const Systems = () => {
  return (
    <section id="systems" className="py-24 bg-gradient-to-b from-black to-gray-900">
      <div className="container mx-auto px-4">
        <h2 className="section-title pb-4 mb-16">
          <Cpu className="inline-block w-8 h-8 text-honda-red mr-2" />
          Our Systems
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {systems.map((system, index) => (
            <div 
              key={index}
              className="premium-card hover-effect group"
            >
              <div className="flex items-start gap-6">
                <div className="w-12 h-12 round   ed-full bg-honda-red/10 flex items-center justify-center shrink-0">
                  <Cpu className="w-6 h-6 text-honda-red" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-3 group-hover:text-honda-red transition-colors">
                    {system.name}
                  </h3>
                  <p className="text-neutral-400 mb-4">{system.description}</p>
                  <ul className="space-y-2">
                    {system.features.map((feature, featureIndex) => (
                      <li 
                        key={featureIndex}
                        className="flex items-center text-sm text-neutral-300"
                      >
                        <span className="w-1.5 h-1.5 rounded-full bg-honda-red mr-2" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-16 max-w-4xl mx-auto text-center">
          <p className="text-lg text-neutral-400">
            Our state-of-the-art diagnostic and maintenance systems ensure your motorcycle receives the most accurate and efficient service possible.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Systems;