import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import About from './components/About';
import Mission from './components/Mission';
import Services from './components/Services';
import Systems from './components/Systems';
import Partners from './components/Partners';
import Gallery from './components/Gallery';
import Testimonials from './components/Testimonials';
import WhereWeAre from './components/WhereWeAre';
import Guide from './components/Guide';
{/*import Contact from './components/Contact';*/}
import Footer from './components/Footer';

function App() {
  return (
    <div className="min-h-screen bg-black text-white">
      {/* Navbar Section */}
      <header>
        <Navbar />
      </header>

      {/* Main Content */}
      <main>
        <Hero />
        <About />
        <Mission />
        <Services />
        <Systems />
        <Partners />
        <Gallery />
        <Testimonials />
        <Guide /> {/* Aggiungi qui la sezione Guide */}
        <WhereWeAre />
        {/* <Contact /> */}
      </main>

      {/* Footer Section */}
      <footer>
        <Footer />
      </footer>
    </div>
  );
}


export default App;
