import React, { useState, useEffect } from 'react';
import { Menu, X, ChevronDown } from 'lucide-react';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };  
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`fixed w-full z-50 transition-all duration-300 ${isScrolled ? 'bg-black/90 backdrop-blur-md shadow-lg' : ''}`}>
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-20">
          {/* Loghi */}
          <div className="flex items-center space-x-6">
            <img
              src="/bostmoto.png"
              alt="Bost Moto"
              className="h-16"
            />
            <img
              src="/honda-logo.png"
              alt="Honda"
              className="h-12"
            />
          </div>

          {/* Navigazione */}
          <nav className="hidden md:block">
            <ul className="flex items-center space-x-8">
              <li><a href="#home" className="nav-link">Home</a></li>
              <li className="relative group">
                <a href="#about" className="nav-link flex items-center">
                  Chi Siamo <ChevronDown className="ml-1 h-4 w-4" />
                </a>
                <ul className="absolute hidden group-hover:block bg-black/90 backdrop-blur-md rounded-lg p-2 w-48">
                  <li><a href="#team" className="nav-link block px-4 py-2">Il Nostro Team</a></li>
                  <li><a href="#history" className="nav-link block px-4 py-2">La Nostra Storia</a></li>
                </ul>
              </li>
              <li><a href="#services" className="nav-link">Servizi</a></li>
              <li><a href="#systems" className="nav-link">Sistemi</a></li>
              <li><a href="#partners" className="nav-link">Partner</a></li>
              <li><a href="#gallery" className="nav-link">Galleria</a></li>
              <li><a href="#guide" className="nav-link">Guida</a></li>
              <li><a href="#location" className="nav-link">Dove Siamo</a></li>
              <li><a href="#contact" className="nav-link">Contatti</a></li>
            </ul>
          </nav>

          {/* Pulsante Menu Mobile */}
          <button
            className="md:hidden"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
          </button>
        </div>
      </div>

      {/* Menu Mobile */}
      {isOpen && (
        <div className="md:hidden bg-black/95 backdrop-blur-md">
          <ul className="px-4 pt-2 pb-4">
            <li><a href="#home" className="mobile-nav-link">Home</a></li>
            <li><a href="#about" className="mobile-nav-link">Chi Siamo</a></li>
            <li><a href="#services" className="mobile-nav-link">Servizi</a></li>
            {/* <li><a href="#guide" className="mobile-nav-link">Guida</a></li> */}
            <li><a href="#systems" className="mobile-nav-link">Sistemi</a></li>
            <li><a href="#gallery" className="mobile-nav-link">Galleria</a></li>
            <li><a href="#location" className="mobile-nav-link">Dove Siamo</a></li>
            <li><a href="#contact" className="mobile-nav-link">Contatti</a></li>
          </ul>
        </div>
      )}
    </header>
  );
};

export default Navbar;
