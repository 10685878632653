import React from 'react';
import { BookOpen, ChevronRight } from 'lucide-react';

const Guide = () => {
  return (
    <div className="pt-16 pb-24 bg-gradient-to-b from-black to-gray-900">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold text-center mb-16">
          <BookOpen className="inline-block w-8 h-8 text-honda-red mr-2" />
          Guida alla Manutenzione
        </h1>

        <div className="max-w-4xl mx-auto space-y-16">
          {/* Introduction Section */}
          <div className="premium-card hover-effect">
            <h2 className="text-3xl font-light mb-8">Perché è importante fare il tagliando?</h2>
            <p className="text-lg text-neutral-300 mb-8 leading-relaxed">
              Il tagliando è fondamentale per garantire la sicurezza, l'affidabilità e le prestazioni della tua moto. Una manutenzione regolare permette di:
            </p>
            <ul className="space-y-8">
              <li className="flex items-start space-x-4">
                <ChevronRight className="w-6 h-6 text-honda-red shrink-0 mt-1" />
                <div>
                  <h3 className="text-xl font-medium mb-2">Prevenire guasti:</h3>
                  <p className="text-neutral-400">
                    Individuando e risolvendo eventuali problemi prima che si trasformino in guasti più gravi.
                  </p>
                </div>
              </li>
              {/* Altri punti */}
            </ul>
          </div>

          {/* Additional Sections */}
          <div className="premium-card hover-effect">
            <h2 className="text-2xl font-light mb-6">Frequenza dei Tagliandi</h2>
            <p className="text-lg text-neutral-300 leading-relaxed">
              La frequenza dei tagliandi è indicata nel manuale d'uso della moto e dipende solitamente dal chilometraggio percorso o dal tempo trascorso dall'ultimo tagliando.
            </p>
          </div>
          <div className="premium-card hover-effect">
            <h2 className="text-2xl font-light mb-6">Dove fare il tagliando?</h2>
            <p className="text-lg text-neutral-300 leading-relaxed">
              Puoi effettuare il tagliando presso un'officina autorizzata o presso un'officina specializzata in moto.
            </p>
            <div className="mt-6">
              <a
                href="/contact"
                className="inline-flex items-center gap-2 px-6 py-3 bg-honda-red text-white rounded-lg hover:bg-red-700 transition-colors"
              >
                Prenota il tuo tagliando
                <ChevronRight className="w-4 h-4" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Guide;
