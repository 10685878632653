import React, { useState } from 'react';
import { Image, ChevronLeft, ChevronRight } from 'lucide-react';

const images = [
  {
    url: '/officinainside.jpg', // Usa il percorso assoluto per le immagini nella cartella public
    title: 'Dove i sogni diventano realtà',
    description: 'Entra nella nostra officina, dove la passione incontra la precisione. I nostri tecnici esperti si dedicano a trasformare i tuoi sogni motociclistici in realtà, curando ogni dettaglio con attenzione e competenza.',
    type: 'image',
  },
  {
    url: '/videovintage.mp4', // Usa il percorso assoluto per le immagini nella cartella public
    title: 'Sapore vintage',
    description: 'Fai un viaggio nel tempo mentre mostriamo il meticoloso restauro di una Vespa 50 Special d’epoca. Da uno stato trascurato e invecchiato a un capolavoro magnificamente restaurato, assisti alla dedizione e all’artigianalità che riportano in vita questo iconico scooter.',
    type: 'video',
  },
];

const Gallery = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prev) => (prev + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prev) => (prev - 1 + images.length) % images.length);
  };

  return (
    <section id="gallery" className="py-24 bg-gradient-to-b from-gray-900 to-black">
      <div className="container mx-auto px-4">
        <h2 className="section-title pb-4 mb-16">
          <Image className="inline-block w-8 h-8 text-red-600 mr-2" />
          Galleria
        </h2>
  
        <div className="relative max-w-5xl mx-auto">
          <div className="aspect-w-16 aspect-h-9 rounded-xl overflow-hidden">
            {images[currentIndex].type === 'image' ? (
              <img
                src={images[currentIndex].url}
                alt={images[currentIndex].title}
                className="w-full h-full object-cover transition-opacity duration-500"
              />
            ) : (
              <video
                src={images[currentIndex].url}
                controls
                autoPlay
                className="w-full h-full"
              />
            )}
          </div>
  
          <div className="absolute inset-0 flex items-center justify-between p-4">
            <button
              onClick={prevSlide}
              className="p-2 rounded-full bg-black/50 text-white backdrop-blur-sm
                       transform transition hover:scale-110"
            >
              <ChevronLeft className="w-6 h-6" />
            </button>
            <button
              onClick={nextSlide}
              className="p-2 rounded-full bg-black/50 text-white backdrop-blur-sm
                       transform transition hover:scale-110"
            >
              <ChevronRight className="w-6 h-6" />
            </button>
          </div>
  
          <div className="mt-6 text-center">
            <h3 className="text-2xl font-bold mb-2">{images[currentIndex].title}</h3>
            <p className="text-gray-400">{images[currentIndex].description}</p>
          </div>
  
          <div className="flex justify-center mt-6 space-x-2">
            {images.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentIndex(index)}
                className={`w-3 h-3 rounded-full transition-all ${
                  index === currentIndex ? 'bg-red-600 w-6' : 'bg-gray-600'
                }`}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gallery;
